import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { apiPath } from '../app.constant';
declare const moment: any;
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor(private http: HttpClient) { }
  // Holidays() {
  //   return this.http.get(`${apiPath}Holidays`);
  // }
  getDetailsByToken(data: any) {
    return this.http.post(`${apiPath}guest/get-details-by-token`, { guest_token: data });
  }
}
