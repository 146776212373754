import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from './../common.service';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.css']
})
export class BookingDetailsComponent implements OnInit {
  public token: string;
  loading: boolean = false;
  guestDetails: any;
  errorMessage:string;
  error:boolean = false;

  constructor(private route: ActivatedRoute, private commonService: CommonService) { }

  ngOnInit() {
    this.token = this.route.snapshot.paramMap.get('token');
    this.getGuestDeatails();
  }

  getGuestDeatails() {
    this.loading = true;

    this.commonService.getDetailsByToken(this.token).subscribe((data: any) => {
      this.loading = false;
      if(data.success) {
        this.error = false;
        this.guestDetails = data.data.bookingData;
      } else {
        this.error = true;
        this.errorMessage = "Invalid token";
      }
    });
  }

}
