import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BookingDetailsComponent } from './pages/booking-details/booking-details.component';
import { ExtendComponent } from './pages/extend/extend.component';
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';



const routes: Routes = [
  // { path: '', redirectTo: '404', pathMatch: 'full' },
  { path: 'bookingDetails/:token', component: BookingDetailsComponent },
  { path: 'extend', component: ExtendComponent },
  { path: 'paymentSuccess', component: PaymentSuccessComponent },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
