import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-extend',
  templateUrl: './extend.component.html',
  styleUrls: ['./extend.component.css']
})
export class ExtendComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
